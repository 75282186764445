import { render, staticRenderFns } from "./TrainingStudentExamineeAggregate.vue?vue&type=template&id=789f6ca9&scoped=true"
import script from "./TrainingStudentExamineeAggregate.vue?vue&type=script&lang=js"
export * from "./TrainingStudentExamineeAggregate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "789f6ca9",
  null
  
)

export default component.exports